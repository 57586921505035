import React from "react";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation(); // Get current route

  console.log(location);

  return (
    <header id="header" className="header d-flex align-items-center sticky-top">
      <div className="container-fluid position-relative d-flex align-items-center justify-content-between">
        <div style={{ marginLeft: "1rem" }}>
          <a href="/">
            <img style={{ width: "53px" }} src="assets/img/logo.png" alt="" />
          </a>
        </div>

        <nav id="navmenu" className="navmenu">
          <ul>
            <li>
              <Link
                to="/"
                className={location.pathname === "/" ? "active" : ""}
              >
                Home
                <br />
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className={location.pathname === "/about" ? "active" : ""}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="/services"
                className={
                  location.pathname === "/services" ||
                  location.pathname === "/service-list"
                    ? "active"
                    : ""
                }
              >
                Services
              </Link>
            </li>

            <li>
              <Link
                to="/team"
                className={location.pathname === "/team" ? "active" : ""}
              >
                Team
              </Link>
            </li>

            <li>
              <Link
                to="/contact"
                className={location.pathname === "/contact" ? "active" : ""}
              >
                Contact
              </Link>
            </li>
          </ul>
          <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
        </nav>

        <a className="" href="index.html#about">
          {/* Get Started */}
        </a>
      </div>
    </header>
  );
};

export default Header;
