import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Services = () => {
  const [servicesData, setServicesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Simulate a data fetching function
    const fetchServices = async () => {
      try {
        // Simulate an API call (you can replace this with your actual data fetching logic)
        const fetchedServices = [
          {
            title: "Sales and service",
            description: "Click Here For More Details ...",
            icon: "bi-activity",
            imgSrc: "assets/img/services-1.jpg",
            delay: "200",
            services: [
              {
                title: "TV Sales and Service",
                description: "Click Here For More Details ...",
                icon: "bi-activity",
                imgSrc: "assets/img/services-1.jpg",
                delay: "200",
              },
              {
                title: "Mobile Sales and service",
                description: "Click Here For More Details ...",
                icon: "bi-activity",
                imgSrc: "assets/img/services-1.jpg",
                delay: "200",
              },
            ],
          },
          {
            title: "Vehicles",
            description: "Click Here For More Details ...",
            icon: "bi-broadcast",
            imgSrc: "assets/img/services-2.jpg",
            delay: "300",
            services: [
              {
                title: "Two wheeler sales and service",
                description: "Click Here For More Details ...",
                icon: "bi-broadcast",
                imgSrc: "assets/img/services-2.jpg",
                delay: "300",
              },
              {
                title: "Four wheeler sales and service",
                description: "Click Here For More Details ...",
                icon: "bi-broadcast",
                imgSrc: "assets/img/services-2.jpg",
                delay: "300",
              },
              {
                title: "Driving training and license assistance (RTO)",
                description: "Click Here For More Details ...",
                icon: "bi-broadcast",
                imgSrc: "assets/img/services-2.jpg",
                delay: "300",
              },
            ],
          },
          {
            title: "Tours and Travels",
            description: "Click Here For More Details ...",
            icon: "bi-easel",
            imgSrc: "assets/img/services-3.jpg",
            delay: "400",
            services: [
              {
                title: "Rented cars",
                description: "Click Here For More Details ...",
                icon: "bi-easel",
                imgSrc: "assets/img/services-3.jpg",
                delay: "400",
              },
              {
                title: "Tour Package",
                description: "Click Here For More Details ...",
                icon: "bi-easel",
                imgSrc: "assets/img/services-3.jpg",
                delay: "400",
              },
              {
                title: "Passport service",
                description: "Click Here For More Details ...",
                icon: "bi-easel",
                imgSrc: "assets/img/services-3.jpg",
                delay: "400",
              },
            ],
          },
          {
            title: "Camera",
            description: "Click Here For More Details ...",
            icon: "bi-bounding-box-circles",
            imgSrc: "assets/img/services-4.jpg",
            delay: "500",
            services: [
              {
                title: "Camera sales",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/services-4.jpg",
                delay: "500",
              },
              {
                title: "Camera Services",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/services-4.jpg",
                delay: "500",
              },
              {
                title: "Camera Rent",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/services-4.jpg",
                delay: "500",
              },
            ],
          },
          {
            title: "Household services",
            description: "Click Here For More Details ...",
            icon: "bi-calendar4-week",
            imgSrc: "assets/img/services-5.jpg",
            delay: "600",
            services: [
              {
                title: "Tank clean",
                description: "Click Here For More Details ...",
                icon: "bi-calendar4-week",
                imgSrc: "assets/img/services-5.jpg",
                delay: "600",
              },
              {
                title: "Plumbing",
                description: "Click Here For More Details ...",
                icon: "bi-calendar4-week",
                imgSrc: "assets/img/services-5.jpg",
                delay: "600",
              },
              {
                title: "Electrical service",
                description: "Click Here For More Details ...",
                icon: "bi-calendar4-week",
                imgSrc: "assets/img/services-5.jpg",
                delay: "600",
              },
              {
                title: "House Cleaning",
                description: "Click Here For More Details ...",
                icon: "bi-calendar4-week",
                imgSrc: "assets/img/services-5.jpg",
                delay: "600",
              },
              {
                title: "Carpentry",
                description: "Click Here For More Details ...",
                icon: "bi-calendar4-week",
                imgSrc: "assets/img/services-5.jpg",
                delay: "600",
              },
              {
                title: "House shifting service",
                description: "Click Here For More Details ...",
                icon: "bi-calendar4-week",
                imgSrc: "assets/img/services-5.jpg",
                delay: "600",
              },
              {
                title: "House Maid service",
                description: "Click Here For More Details ...",
                icon: "bi-calendar4-week",
                imgSrc: "assets/img/services-5.jpg",
                delay: "600",
              },
              {
                title: "Solar service",
                description: "Click Here For More Details ...",
                icon: "bi-calendar4-week",
                imgSrc: "assets/img/services-5.jpg",
                delay: "600",
              },
            ],
          },
          {
            title: "Real Estate service",
            description: "Click Here For More Details ...",
            icon: "bi-chat-square-text",
            imgSrc: "assets/img/services-6.jpg",
            delay: "700",
            services: [
              {
                title: "House / apartment / Villa rent / lease",
                description: "Click Here For More Details ...",
                icon: "bi-chat-square-text",
                imgSrc: "assets/img/services-6.jpg",
                delay: "700",
              },
              {
                title: "House / apartment / Villa sale / purchase",
                description: "Click Here For More Details ...",
                icon: "bi-chat-square-text",
                imgSrc: "assets/img/services-6.jpg",
                delay: "700",
              },
              {
                title: "Land / agricultural land / farm - rent / lease",
                description: "Click Here For More Details ...",
                icon: "bi-chat-square-text",
                imgSrc: "assets/img/services-6.jpg",
                delay: "700",
              },
              {
                title: "Land / agricultural land / farm - Sale / Purchase",
                description: "Click Here For More Details ...",
                icon: "bi-chat-square-text",
                imgSrc: "assets/img/services-6.jpg",
                delay: "700",
              },
            ],
          },
          {
            title: "Civil Construction",
            description: "Click Here For More Details ...",
            icon: "bi-activity",
            imgSrc: "assets/img/services-1.jpg",
            delay: "200",
            services: [
              {
                title: "Structural engineering service",
                description: "Click Here For More Details ...",
                icon: "bi-activity",
                imgSrc: "assets/img/services-1.jpg",
                delay: "200",
              },
              {
                title: "Architecture Design and planning",
                description: "Click Here For More Details ...",
                icon: "bi-activity",
                imgSrc: "assets/img/services-1.jpg",
                delay: "200",
              },
              {
                title: "3D Walkthrough",
                description: "Click Here For More Details ...",
                icon: "bi-activity",
                imgSrc: "assets/img/services-1.jpg",
                delay: "200",
              },
              {
                title: "Construction service",
                description: "Click Here For More Details ...",
                icon: "bi-activity",
                imgSrc: "assets/img/services-1.jpg",
                delay: "200",
              },
              {
                title: "Construction Material supply",
                description: "Click Here For More Details ...",
                icon: "bi-activity",
                imgSrc: "assets/img/services-1.jpg",
                delay: "200",
              },
              {
                title: "Lift service",
                description: "Click Here For More Details ...",
                icon: "bi-activity",
                imgSrc: "assets/img/services-1.jpg",
                delay: "200",
              },
              {
                title: "Generator service",
                description: "Click Here For More Details ...",
                icon: "bi-activity",
                imgSrc: "assets/img/services-1.jpg",
                delay: "200",
              },
              {
                title: "Escalator service",
                description: "Click Here For More Details ...",
                icon: "bi-activity",
                imgSrc: "assets/img/services-1.jpg",
                delay: "200",
              },
            ],
          },
          {
            title: "Event management",
            description: "Click Here For More Details ...",
            icon: "bi-broadcast",
            imgSrc: "assets/img/services-2.jpg",
            delay: "300",
            services: [
              {
                title: "Marriage event",
                description: "Click Here For More Details ...",
                icon: "bi-broadcast",
                imgSrc: "assets/img/services-2.jpg",
                delay: "300",
              },
              {
                title: "Pre wedding",
                description: "Click Here For More Details ...",
                icon: "bi-broadcast",
                imgSrc: "assets/img/services-2.jpg",
                delay: "300",
              },
              {
                title: "Birthday / House warming etc",
                description: "Click Here For More Details ...",
                icon: "bi-broadcast",
                imgSrc: "assets/img/services-2.jpg",
                delay: "300",
              },
              {
                title: "Cooking Contract",
                description: "Click Here For More Details ...",
                icon: "bi-broadcast",
                imgSrc: "assets/img/services-2.jpg",
                delay: "300",
              },
              {
                title: "Decoration contract",
                description: "Click Here For More Details ...",
                icon: "bi-broadcast",
                imgSrc: "assets/img/services-2.jpg",
                delay: "300",
              },
              {
                title: "Infrastructure service for event",
                description: "Click Here For More Details ...",
                icon: "bi-broadcast",
                imgSrc: "assets/img/services-2.jpg",
                delay: "300",
              },
              {
                title: "Wedding hall (kalyana Mantapa) Rent",
                description: "Click Here For More Details ...",
                icon: "bi-broadcast",
                imgSrc: "assets/img/services-2.jpg",
                delay: "300",
              },
              {
                title: "Birthday hall for rent",
                description: "Click Here For More Details ...",
                icon: "bi-broadcast",
                imgSrc: "assets/img/services-2.jpg",
                delay: "300",
              },
            ],
          },
          {
            title: "Finance",
            description: "Click Here For More Details ...",
            icon: "bi-easel",
            imgSrc: "assets/img/services-3.jpg",
            delay: "400",
            services: [
              {
                title: "Housing Loans",
                description: "Click Here For More Details ...",
                icon: "bi-easel",
                imgSrc: "assets/img/services-3.jpg",
                delay: "400",
              },
              {
                title: "Personal Loan",
                description: "Click Here For More Details ...",
                icon: "bi-easel",
                imgSrc: "assets/img/services-3.jpg",
                delay: "400",
              },
              {
                title: "Business Loan",
                description: "Click Here For More Details ...",
                icon: "bi-easel",
                imgSrc: "assets/img/services-3.jpg",
                delay: "400",
              },
            ],
          },
          {
            title: "Medical Service",
            description: "Click Here For More Details ...",
            icon: "bi-bounding-box-circles",
            imgSrc: "assets/img/services-4.jpg",
            delay: "500",
            services: [
              {
                title: "Health care Specialist Service",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/services-4.jpg",
                delay: "500",
              },
              {
                title: "Ayurvedic service",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/services-4.jpg",
                delay: "500",
              },
              {
                title: "Acupuncture service",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/services-4.jpg",
                delay: "500",
              },
            ],
          },
          {
            title: "Matrimony Service",
            description: "Click Here For More Details ...",
            icon: "bi-calendar4-week",
            imgSrc: "assets/img/services-5.jpg",
            delay: "600",
          },
          {
            title: "Employment service",
            description: "Click Here For More Details ...",
            icon: "bi-chat-square-text",
            imgSrc: "assets/img/services-6.jpg",
            delay: "700",
          },
          {
            title: "Training and skill development",
            description: "Click Here For More Details ...",
            icon: "bi-bounding-box-circles",
            imgSrc: "assets/img/services-4.jpg",
            delay: "500",
            services: [
              {
                title: "Cinema acting – Direction training",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/services-4.jpg",
                delay: "500",
              },
              {
                title: "VFX training",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/services-4.jpg",
                delay: "500",
              },
              {
                title: "Editing training",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/services-4.jpg",
                delay: "500",
              },
              {
                title: "Color Grading Training",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/services-4.jpg",
                delay: "500",
              },
              {
                title: "Cinematography",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/services-4.jpg",
                delay: "500",
              },
              {
                title: "Beautician Training",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/services-4.jpg",
                delay: "500",
              },
              {
                title: "Animation Training",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/services-4.jpg",
                delay: "500",
              },
              {
                title: "Web application design and development",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/services-4.jpg",
                delay: "500",
              },
              {
                title: "Hardware and networking training",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/services-4.jpg",
                delay: "500",
              },
            ],
          },
          {
            title: "Government service",
            description: "Click Here For More Details ...",
            icon: "bi-calendar4-week",
            imgSrc: "assets/img/services-5.jpg",
            delay: "600",
            services: [
              {
                title: "Ration card registration",
                description: "Click Here For More Details ...",
                icon: "bi-calendar4-week",
                imgSrc: "assets/img/services-5.jpg",
                delay: "600",
              },
              {
                title: "Adhar / PAN service",
                description: "Click Here For More Details ...",
                icon: "bi-calendar4-week",
                imgSrc: "assets/img/services-5.jpg",
                delay: "600",
              },
              {
                title: "Other Govt certificate services",
                description: "Click Here For More Details ...",
                icon: "bi-calendar4-week",
                imgSrc: "assets/img/services-5.jpg",
                delay: "600",
              },
            ],
          },
          {
            title: "Hotel services including lodging / Rooms",
            description: "Click Here For More Details ...",
            icon: "bi-chat-square-text",
            imgSrc: "assets/img/services-6.jpg",
            delay: "700",
          },
        ];

        if (fetchedServices.length === 0) {
          setNoData(true);
        } else {
          setServicesData(fetchedServices);
        }
      } catch (error) {
        console.error("Error fetching services:", error);
        setNoData(true);
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // You can replace this with a loading spinner or animation
  }

  if (noData) {
    return <div>No Data Available</div>;
  }

  return (
    <section id="services" className="services section">
      <div className="container section-title" data-aos="fade-up">
        <h2>Our Services</h2>
        <p>
          Explore a wide range of services tailored to meet your needs, from
          expert consultations to comprehensive solutions, ensuring exceptional
          results for every client
        </p>
      </div>
      {/* End Section Title */}

      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="row gy-5">
          {servicesData?.map((service, index) => (
            <div
              key={index}
              className="col-xl-4 col-md-6"
              data-aos="zoom-in"
              data-aos-delay={service.delay}
            >
              <div className="service-item">
                <div className="img">
                  <img
                    src={service.imgSrc}
                    className="img-fluid"
                    alt={service.title}
                  />
                </div>
                <div className="details position-relative">
                  <div className="icon">
                    <i className={`bi ${service.icon}`}></i>
                  </div>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate("/service-list", {
                        state: {
                          services: service.services,
                          title: service.title,
                        },
                      })
                    }
                    className="stretched-link"
                  >
                    <h3>{service.title}</h3>
                  </a>
                  <p
                    onClick={() =>
                      navigate("/service-list", {
                        state: {
                          services: service.services,
                          title: service.title,
                        },
                      })
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {service.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
