import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import ServicesList from "./pages/ServiceList";
import ServiceDetails from "./pages/ServicesDeatils";
import Team from "./pages/Team";
import Contact from "./pages/Contact";
import ScrollToTop from "./helper/ScrollToTop";

function App() {
  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/service-list" element={<ServicesList />} />
        <Route path="/service-details" element={<ServiceDetails />} />
        <Route path="/team" element={<Team />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
