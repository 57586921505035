import React, { useState } from "react";

const About = () => {
  const [activeTab, setActiveTab] = useState("features-tab-1");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  return (
    <>
      <section id="about" className="about section">
        {/* Section Title */}
        <div className="container section-title" data-aos="fade-up">
          <h2>About Us</h2>
          <p>
            Necessitatibus eius consequatur ex aliquid fuga eum quidem sint
            consectetur velit
          </p>
        </div>
        {/* End Section Title */}

        <div className="container" data-aos="fade-up">
          <div
            className="row g-4 g-lg-5"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="col-lg-5">
              <div className="about-img">
                <img
                  src="assets/img/about-portrait.jpg"
                  className="img-fluid"
                  alt="About"
                />
              </div>
            </div>

            <div className="col-lg-7">
              <h3 className="pt-0 pt-lg-5">
                Neque officiis dolore maiores et exercitationem quae est seda
                lidera pat claero
              </h3>
              <p>
                The gentle rustle of leaves whispered through the tranquil
                forest, creating a serene symphony of nature's melodies.
                Sunlight filtered through the dense canopy, casting dappled
                shadows on the forest floor where delicate ferns and wildflowers
                thrived. The air was crisp and invigorating, filled with the
                earthy scent of moss and the subtle fragrance of blooming flora.
                A meandering stream glistened in the sunlight, its clear waters
                babbling over smooth stones, adding a soothing background to the
                harmonious sounds of chirping birds and distant animal calls. In
                this peaceful sanctuary, time seemed to slow, allowing one to
                escape the hustle and bustle of everyday life and find solace in
                the natural beauty that surrounded them.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="clients" className="clients section">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            <div className="col-xl-2 col-md-3 col-6 client-logo">
              <img
                src="assets/img/clients/client-1.png"
                className="img-fluid"
                alt="Client 1"
              />
            </div>
            {/* End Client Item */}

            <div className="col-xl-2 col-md-3 col-6 client-logo">
              <img
                src="assets/img/clients/client-2.png"
                className="img-fluid"
                alt="Client 2"
              />
            </div>
            {/* End Client Item */}

            <div className="col-xl-2 col-md-3 col-6 client-logo">
              <img
                src="assets/img/clients/client-3.png"
                className="img-fluid"
                alt="Client 3"
              />
            </div>
            {/* End Client Item */}

            <div className="col-xl-2 col-md-3 col-6 client-logo">
              <img
                src="assets/img/clients/client-4.png"
                className="img-fluid"
                alt="Client 4"
              />
            </div>
            {/* End Client Item */}

            <div className="col-xl-2 col-md-3 col-6 client-logo">
              <img
                src="assets/img/clients/client-5.png"
                className="img-fluid"
                alt="Client 5"
              />
            </div>
            {/* End Client Item */}

            <div className="col-xl-2 col-md-3 col-6 client-logo">
              <img
                src="assets/img/clients/client-6.png"
                className="img-fluid"
                alt="Client 6"
              />
            </div>
            {/* End Client Item */}
          </div>
        </div>
      </section>
      <section id="call-to-action" className="call-to-action section">
        <div className="container" data-aos="zoom-out">
          <div className="row g-5">
            <div className="col-lg-8 col-md-6 content d-flex flex-column justify-content-center order-last order-md-first">
              <h3>
                Alias sunt quas <em>Cupiditate</em> oluptas hic minima
              </h3>
              <p>
                Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </p>
              <a className="cta-btn align-self-start" href="#">
                Call To Action
              </a>
            </div>

            <div className="col-lg-4 col-md-6 order-first order-md-last d-flex align-items-center">
              <div className="img">
                <img
                  src="assets/img/cta.jpg"
                  alt="Call to Action"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="onfocus" className="onfocus section dark-background">
        <div className="container-fluid p-0" data-aos="fade-up">
          <div className="row g-0">
            <div className="col-lg-6 video-play position-relative">
              <a
                href="https://www.youtube.com/watch?v=Y7f98aduVJ8"
                className="glightbox pulsating-play-btn"
              ></a>
            </div>
            <div className="col-lg-6">
              <div className="content d-flex flex-column justify-content-center h-100">
                <h3>Voluptatem dignissimos provident quasi corporis</h3>
                <p className="fst-italic">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
                <ul>
                  <li>
                    <i className="bi bi-check-circle"></i> Ullamco laboris nisi
                    ut aliquip ex ea commodo consequat.
                  </li>
                  <li>
                    <i className="bi bi-check-circle"></i> Duis aute irure dolor
                    in reprehenderit in voluptate velit.
                  </li>
                  <li>
                    <i className="bi bi-check-circle"></i> Ullamco laboris nisi
                    ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate trideta storacalaperda mastiro
                    dolore eu fugiat nulla pariatur.
                  </li>
                </ul>
                <a href="#" className="read-more align-self-start">
                  <span>Read More</span>
                  <i className="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="features section">
        <div className="container" data-aos="fade-up">
          <ul className="nav nav-tabs row gy-4 d-flex">
            <li className="nav-item col-6 col-md-4 col-lg-2">
              <a
                className={`nav-link ${
                  activeTab === "features-tab-1" ? "active show" : ""
                }`}
                onClick={() => handleTabClick("features-tab-1")}
              >
                <i
                  className="bi bi-binoculars"
                  style={{ color: "#0dcaf0" }}
                ></i>
                <h4>Modinest</h4>
              </a>
            </li>

            <li className="nav-item col-6 col-md-4 col-lg-2">
              <a
                className={`nav-link ${
                  activeTab === "features-tab-2" ? "active show" : ""
                }`}
                onClick={() => handleTabClick("features-tab-2")}
              >
                <i className="bi bi-box-seam" style={{ color: "#6610f2" }}></i>
                <h4>Undaesenti</h4>
              </a>
            </li>

            <li className="nav-item col-6 col-md-4 col-lg-2">
              <a
                className={`nav-link ${
                  activeTab === "features-tab-3" ? "active show" : ""
                }`}
                onClick={() => handleTabClick("features-tab-3")}
              >
                <i
                  className="bi bi-brightness-high"
                  style={{ color: "#20c997" }}
                ></i>
                <h4>Pariatur</h4>
              </a>
            </li>

            <li className="nav-item col-6 col-md-4 col-lg-2">
              <a
                className={`nav-link ${
                  activeTab === "features-tab-4" ? "active show" : ""
                }`}
                onClick={() => handleTabClick("features-tab-4")}
              >
                <i className="bi bi-command" style={{ color: "#df1529" }}></i>
                <h4>Nostrum</h4>
              </a>
            </li>

            <li className="nav-item col-6 col-md-4 col-lg-2">
              <a
                className={`nav-link ${
                  activeTab === "features-tab-5" ? "active show" : ""
                }`}
                onClick={() => handleTabClick("features-tab-5")}
              >
                <i className="bi bi-easel" style={{ color: "#0d6efd" }}></i>
                <h4>Adipiscing</h4>
              </a>
            </li>

            <li className="nav-item col-6 col-md-4 col-lg-2">
              <a
                className={`nav-link ${
                  activeTab === "features-tab-6" ? "active show" : ""
                }`}
                onClick={() => handleTabClick("features-tab-6")}
              >
                <i className="bi bi-map" style={{ color: "#fd7e14" }}></i>
                <h4>Reprehit</h4>
              </a>
            </li>
          </ul>

          <div className="tab-content">
            <div
              className={`tab-pane fade ${
                activeTab === "features-tab-1" ? "active show" : ""
              }`}
              id="features-tab-1"
            >
              <div className="row gy-4">
                <div
                  className="col-lg-8 order-2 order-lg-1"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <h3>Modinest</h3>
                  <p className="fst-italic">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit...
                  </p>
                  <ul>
                    <li>
                      <i className="bi bi-check-circle-fill"></i> Ullamco
                      laboris nisi ut aliquip ex ea commodo consequat.
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill"></i> Duis aute
                      irure dolor in reprehenderit in voluptate velit.
                    </li>
                  </ul>
                  <p>
                    {" "}
                    Ullamco laboris nisi ut aliquip ex ea commodo consequat...
                  </p>
                </div>
                <div
                  className="col-lg-4 order-1 order-lg-2 text-center"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <img
                    src="assets/img/features-1.svg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "features-tab-2" ? "active show" : ""
              }`}
              id="features-tab-2"
            >
              <div className="row gy-4">
                <div className="col-lg-8 order-2 order-lg-1">
                  <h3>Undaesenti</h3>
                  <p>
                    {" "}
                    Ullamco laboris nisi ut aliquip ex ea commodo consequat...
                  </p>
                  <ul>
                    <li>
                      <i className="bi bi-check-circle-fill"></i> Ullamco
                      laboris nisi ut aliquip ex ea commodo consequat.
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 order-1 order-lg-2 text-center">
                  <img
                    src="assets/img/features-2.svg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "features-tab-3" ? "active show" : ""
              }`}
              id="features-tab-3"
            >
              <div className="row gy-4">
                <div className="col-lg-8 order-2 order-lg-1">
                  <h3>Pariatur</h3>
                  <ul>
                    <li>
                      <i className="bi bi-check-circle-fill"></i> Ullamco
                      laboris nisi ut aliquip ex ea commodo consequat.
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 order-1 order-lg-2 text-center">
                  <img
                    src="assets/img/features-3.svg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "features-tab-4" ? "active show" : ""
              }`}
              id="features-tab-4"
            >
              <div className="row gy-4">
                <div className="col-lg-8 order-2 order-lg-1">
                  <h3>Nostrum</h3>
                  <ul>
                    <li>
                      <i className="bi bi-check-circle-fill"></i> Ullamco
                      laboris nisi ut aliquip ex ea commodo consequat.
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 order-1 order-lg-2 text-center">
                  <img
                    src="assets/img/features-4.svg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "features-tab-5" ? "active show" : ""
              }`}
              id="features-tab-5"
            >
              <div className="row gy-4">
                <div className="col-lg-8 order-2 order-lg-1">
                  <h3>Adipiscing</h3>
                  <ul>
                    <li>
                      <i className="bi bi-check-circle-fill"></i> Ullamco
                      laboris nisi ut aliquip ex ea commodo consequat.
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 order-1 order-lg-2 text-center">
                  <img
                    src="assets/img/features-5.svg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "features-tab-6" ? "active show" : ""
              }`}
              id="features-tab-6"
            >
              <div className="row gy-4">
                <div className="col-lg-8 order-2 order-lg-1">
                  <h3>Reprehit</h3>
                  <ul>
                    <li>
                      <i className="bi bi-check-circle-fill"></i> Ullamco
                      laboris nisi ut aliquip ex ea commodo consequat.
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 order-1 order-lg-2 text-center">
                  <img
                    src="assets/img/features-6.svg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
