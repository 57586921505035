import React from "react";

const Team = () => {
  return (
    <section id="team" className="team section">
      {/* Section Title */}
      <div className="container section-title" data-aos="fade-up">
        <h2>Our Team</h2>
        <p>
          Necessitatibus eius consequatur ex aliquid fuga eum quidem sint
          consectetur velit
        </p>
      </div>
      {/* End Section Title */}

      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="row gy-5">
          {/* Team Member 1 */}
          <div
            className="col-xl-4 col-md-6 d-flex"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <div className="team-member">
              <div className="member-img">
                <img
                  src="assets/img/team/team-1.jpg"
                  className="img-fluid"
                  alt="Walter White"
                />
              </div>
              <div className="member-info">
                <div className="social">
                  <a href="">
                    <i className="bi bi-twitter-x"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-instagram"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-linkedin"></i>
                  </a>
                </div>
                <h4>Walter White</h4>
                <span>Chief Executive Officer</span>
              </div>
            </div>
          </div>
          {/* End Team Member */}

          {/* Team Member 2 */}
          <div
            className="col-xl-4 col-md-6 d-flex"
            data-aos="zoom-in"
            data-aos-delay="400"
          >
            <div className="team-member">
              <div className="member-img">
                <img
                  src="assets/img/team/team-2.jpg"
                  className="img-fluid"
                  alt="Sarah Jhonson"
                />
              </div>
              <div className="member-info">
                <div className="social">
                  <a href="">
                    <i className="bi bi-twitter-x"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-instagram"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-linkedin"></i>
                  </a>
                </div>
                <h4>Sarah Jhonson</h4>
                <span>Product Manager</span>
              </div>
            </div>
          </div>
          {/* End Team Member */}

          {/* Team Member 3 */}
          <div
            className="col-xl-4 col-md-6 d-flex"
            data-aos="zoom-in"
            data-aos-delay="600"
          >
            <div className="team-member">
              <div className="member-img">
                <img
                  src="assets/img/team/team-1.jpg"
                  className="img-fluid"
                  alt="William Anderson"
                />
              </div>
              <div className="member-info">
                <div className="social">
                  <a href="">
                    <i className="bi bi-twitter-x"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-instagram"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-linkedin"></i>
                  </a>
                </div>
                <h4>William Anderson</h4>
                <span>CTO</span>
              </div>
            </div>
          </div>
          {/* End Team Member */}
        </div>
      </div>
    </section>
  );
};

export default Team;
