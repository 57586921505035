import React from "react";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  const navigate = useNavigate();
  return (
    <>
      <section id="hero" className="hero section">
        <div
          className="container d-flex flex-column justify-content-center align-items-center text-center position-relative"
          data-aos="zoom-out"
        >
          <img
            src="assets/img/hero-img.svg"
            className="img-fluid animated"
            alt=""
          />
          <h1>
            Welcome to <span>MK Services</span>
          </h1>
          <p>
            MK Services, we are dedicated to delivering exceptional solutions
            across a wide range of industries. From expert consultations to
            hands-on support, we ensure your needs are met with the highest
            quality and care."
          </p>
        </div>
      </section>
      <section id="featured-services" className="featured-services section">
        <div className="container">
          <div className="row gy-4">
            <div
              className="col-xl-3 col-md-6 d-flex"
              data-aos="fade-up"
              data-aos-delay="100"
              onClick={() => navigate("/services")}
              style={{ cursor: "pointer" }}
            >
              <div className="service-item position-relative">
                <div className="icon">
                  <i className="bi bi-activity icon"></i>
                </div>
                <h4>Sales and Service Solutions</h4>
                <p>
                  Offering top-quality sales and service for electronics,
                  vehicles, and cameras, including after-sales support and
                  maintenance.
                </p>
              </div>
            </div>

            <div
              className="col-xl-3 col-md-6 d-flex"
              data-aos="fade-up"
              data-aos-delay="200"
              onClick={() => navigate("/services")}
              style={{ cursor: "pointer" }}
            >
              <div className="service-item position-relative">
                <div className="icon">
                  <i className="bi bi-house-door icon"></i>
                </div>
                <h4>Home and Lifestyle Services</h4>
                <p>
                  From household repairs like plumbing and electrical work to
                  house shifting and cleaning, we ensure your home runs
                  smoothly.
                </p>
              </div>
            </div>

            <div
              className="col-xl-3 col-md-6 d-flex"
              data-aos="fade-up"
              data-aos-delay="300"
              onClick={() => navigate("/services")}
              style={{ cursor: "pointer" }}
            >
              <div className="service-item position-relative">
                <div className="icon">
                  <i className="bi bi-airplane-engines icon"></i>
                </div>
                <h4>Travel and Event Management</h4>
                <p>
                  Providing car rentals, tour packages, and end-to-end event
                  management, including weddings, birthdays, and corporate
                  events.
                </p>
              </div>
            </div>

            <div
              className="col-xl-3 col-md-6 d-flex"
              data-aos="fade-up"
              data-aos-delay="400"
              onClick={() => navigate("/services")}
              style={{ cursor: "pointer" }}
            >
              <div className="service-item position-relative">
                <div className="icon">
                  <i className="bi bi-book icon"></i>
                </div>
                <h4>Professional Training and Support</h4>
                <p>
                  Enabling skill development in various fields like
                  cinematography, beautician training, and web development,
                  alongside essential government services.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroSection;
